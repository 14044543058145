module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Tour Food","short_name":"Tour Food","start_url":"/","background_color":"#FB6320","theme_color":"#157EFB","display":"fullscreen","icon":"src/images/tour-food-icon-sq.png","orientation":"portrait"},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-149155173-2"},
    },{
      plugin: require('/opt/build/repo/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
